import { Enumify } from "enumify";

class DfFirebaseTopicType extends Enumify {
  static STORE = new DfFirebaseTopicType({
    getCode(topicName: string): string {
      return `STORE-${topicName}`;
    },
  });
  static BRAND = new DfFirebaseTopicType({
    getCode(topicName: string): string {
      return `BRAND-${topicName}`;
    },
  });
  getCode!: any;
  constructor(props: { [k: string]: any }) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }

  static getEnum(enumKey: string): DfFirebaseTopicType {
    switch (enumKey) {
      case DfFirebaseTopicType.STORE.enumKey:
        return DfFirebaseTopicType.STORE;
      case DfFirebaseTopicType.BRAND.enumKey:
        return DfFirebaseTopicType.BRAND;
      default:
        return null;
    }
  }
}
export default DfFirebaseTopicType;
