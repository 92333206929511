class Firebase {

  public static subscribe (topicName: string) {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.subscribe(
        topicName,
        () => {
          console.log(`Subscribed to the "${topicName}" topic`);
        },
        (error: any) => {
          console.log(`Failed to subscribe to the "${topicName}" topic`, error);
        }
      );
    } else {
      console.log(`FirebasePlugin is not loaded: subscribe`);
    }
  }

  public static unsubscribe (topicName: string) {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.unsubscribe(
        topicName,
        () => {
          console.log(`Unsubscribed to the "${topicName}" topic`);
        },
        (error: any) => {
          console.log(`Failed to unsubscribe to the "${topicName}" topic`, error);
        }
      );
    } else {
      console.log(`FirebasePlugin is not loaded: unsubscribe`);
    }
  }

  public static getId() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.getId((appInstanceId: any) => {
        console.log(`App firebase instance id: ${appInstanceId}`);
      }, (error: any) => {
        console.log(`Failed to get app firebase instance id`, error);
      });
    } else {
      console.log(`FirebasePlugin is not loaded`);
    }
  }

  public static getToken() {
    if ((<any>window).FirebasePlugin) {
      (<any>window).FirebasePlugin.getToken((token: any) => {
        console.log(`App firebase token is: ${token}`);
      }, (error: any) => {
        console.log(`Failed to get app firebase token`, error);
      });
    } else {
      console.log(`FirebasePlugin is not loaded: getToken`);
    }
  }

  public static onMessageReceived(handleNotificationMessage: any, handleDataMessage: any) {
    if ((<any>window).FirebasePlugin) {
      console.log("Firebase onMessageReceived register handlers");
      (<any>window).FirebasePlugin.onMessageReceived(
        (message: any) => {
          try {
            console.log("Firebase Message Received");
            console.dir(message);
            if (message.messageType === "notification") {
              handleNotificationMessage(message);
            } else {
              handleDataMessage(message);
            }
          } catch (e) {
            console.log("Exception in onMessageReceived callback: " + e.message);
          }
        },
        function (error: any) {
          console.log("Failed receiving FirebasePlugin message", error);
        }
      );
    } else {
      console.log(`FirebasePlugin is not loaded: onMessageReceived`);
    }
  }
}

export default Firebase;